<template>
  <div class="courseNameProgressBar">
    <div class="uk-container">
      <div uk-grid>
        <div class="uk-width-1-2@m uk-name">
          <h4>
            <span class="uk-number">{{ lecture.order }}</span>
            {{ lecture.title }}
          </h4>
        </div>
        <div class="uk-width-1-2@m mt-5 uk-progressBar">
          <p>
            {{ $t("attendenceRate") }}
            <span class="progress-num mt-2"> {{ attendenceRate }} %</span>
          </p>
          <progress
            id="js-progressbar"
            class="uk-progress"
            :value="attendenceRate"
            max="100"
          ></progress>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nmae: "CourseIntro",

  props: {
    lecture: Object,
    attendenceRate: String
  }
};
</script>
