<template>
  <div class="video-section">
    <div class="uk-container">
      <iframe
        v-if="lecture.type === 'sheet'"
        :src="getBased(lecture.content_url)"
        height="700px"
        width="100%"
      ></iframe>
      <video
        v-else
        :src="getBased(lecture.content_url)"
        width="100%"
        height="500px"
        autoplay
        playsinline
        controls
        frameborder="0"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseVideo",

  props: {
    lecture: Object
  }
};
</script>
