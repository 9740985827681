<template>
  <div class="header" uk-grid>
    <div class="uk-width-1-2@m">
      <h4>{{ $t("welcomeToCourse") }} {{ info.course_title }}</h4>
    </div>
    <div class="uk-width-1-2@m uk-text-end">
      <router-link v-scroll-to="{el:'#courseInfo', offset: -100}" to="#courseInfo">
        <v-btn class="ml-3 mb-3 mb-md-0 text-lg-h6" :color="primaryColor" dark>
          {{ $t("coursesList.courseInfo") }}
        </v-btn>
      </router-link>
      <router-link router-link :to="{ path: `${info.course_id}/test/modules` }">
        <v-btn
          v-if="$isLogin"
          class="ml-3 mb-3 mb-md-0 text-lg-h6"
          color="#00d4ff"
          dark
        >
          {{ $t("testYourInfo") }}
        </v-btn>
      </router-link>
      <!-- <v-btn class="ml-3 mb-3 mb-md-0 text-lg-h6" color="#ffa950" dark>
        النتائج
      </v-btn> -->
      <br class="hidden-sm-and-up" />
      <a
        @click.prevent="copyPath"
        class="uk-btn-svg"
        :href="'course-info/' + info.course_id"
      >
        <svg width="40.603" height="37.851" viewBox="0 0 40.603 37.851">
          <g id="noun_Share_1483105" transform="translate(0)">
            <path
              id="Path_741"
              data-name="Path 741"
              d="M13.229,46.136a6.726,6.726,0,0,0,3.539-.983L27.042,51a3.151,3.151,0,0,0,1.475.393,2.95,2.95,0,0,0,2.556-1.475,2.981,2.981,0,0,0-1.131-4.031L20.16,40.335a9.576,9.576,0,0,0,.1-1.229c0-.344-.049-.639-.049-.983l9.93-6.046a2.934,2.934,0,0,0-3.048-5.014l-10.077,6.1a7.131,7.131,0,0,0-3.785-1.131,7.054,7.054,0,0,0,0,14.108Zm0-8.16A1.131,1.131,0,1,1,12.1,39.106,1.112,1.112,0,0,1,13.229,37.976Z"
              transform="translate(-6.2 -19.935)"
              fill="#1d1d1b"
            />
            <path
              id="Path_742"
              data-name="Path 742"
              d="M67.229,13a7.029,7.029,0,1,0,7.029,7.029A7.028,7.028,0,0,0,67.229,13Zm0,8.209a1.131,1.131,0,1,1,1.131-1.131A1.112,1.112,0,0,1,67.229,21.209Z"
              transform="translate(-33.656 -13)"
              fill="#1d1d1b"
            />
            <path
              id="Path_743"
              data-name="Path 743"
              d="M67.229,61.3a7.054,7.054,0,1,0,7.029,7.079A7.028,7.028,0,0,0,67.229,61.3Zm0,8.209a1.131,1.131,0,1,1,1.131-1.131A1.112,1.112,0,0,1,67.229,69.509Z"
              transform="translate(-33.656 -37.557)"
              fill="#1d1d1b"
            />
          </g>
        </svg>
      </a>
      <a @click="addCourseToWishListMethod" class="uk-btn-svg">
        <svg width="36.171" height="29.78" viewBox="0 0 36.171 29.78">
          <path
            id="noun_Love_2433259"
            d="M40.266,18.093a9.614,9.614,0,0,1-2.911,7.3L24.927,37.489a3.882,3.882,0,0,1-5.486,0L7.026,25.4A9.569,9.569,0,0,1,7.7,11.034a10.209,10.209,0,0,1,13.541.8,1.339,1.339,0,0,0,1.876,0,10.209,10.209,0,0,1,13.541-.8,9.666,9.666,0,0,1,3.6,7.058Zm-28.278.718a2.536,2.536,0,0,1,.75-1.456,2.7,2.7,0,0,1,1.294-.692,1.295,1.295,0,0,0-.582-2.523,5.324,5.324,0,0,0-2.5,1.365,5.092,5.092,0,0,0-1.507,2.97,1.294,1.294,0,0,0,1.067,1.469h.181A1.294,1.294,0,0,0,11.988,18.811Z"
            transform="translate(-4.104 -8.844)"
            :fill="info.wished === true ? '#e00' : '#1d1d1b'"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../../../../main";
import { mapActions } from "vuex";

export default {
  name: "CourseHeader",

  props: {
    info: {
      type: Object
    }
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("CourseArea", ["addCourseToWishList"]),
    copyPath() {
      try {
        var success = navigator.clipboard.writeText(
          location.hostname + "/course-info/" + this.info.course_id
        );
        if (success) {
          EventBus.$notify({
            group: "public",
            type: "success",
            title: this.$t("copyToClipboard"),
            duration: 4000
          });
        }
      } catch (e) {
        EventBus.$notify({
          group: "public",
          type: "error",
          title: this.$t("failedToCopy"),
          duration: 4000
        });
      }
    },
    addCourseToWishListMethod() {
      if (this.info.wished === true) return;
      this.addCourseToWishList({
        courseId: this.info.course_id
      }).then(() => {
        this.info.wished = true;
      });
    }
  }
};
</script>

<style>
a:hover {
  text-decoration: none !important;
}
</style>
