<template>
  <section
    class="courseInformation-section"
    :class="$i18n.locale === 'en' ? 'en-style' : ''"
  >
    <div class="uk-container">
      <!-- header -->
      <course-header :info="OnlineCourse.onlineCourse" />
      <!-- pagination -->
      <pagination
        v-bind="{
          length: getLeacturesCount,
          index: 1,
          lecturesId: getLecturesId,
          changeCallback: getLectureMethod
        }"
        v-model="id"
      />
      <course-intro
        :lecture="OnlineCourse.lecture"
        :attendenceRate="OnlineCourse.attendenceRate"
      ></course-intro>
      <!-- video -->
      <course-video :lecture="OnlineCourse.lecture"></course-video>
      <!-- info -->
      <course-info
        :info="OnlineCourse.courseIndex"
        :id="id"
        @click="setLecture"
      />
    </div>
  </section>
</template>

<script>
import CourseHeader from "./Components/CourseHeader";
import Pagination from "../../../../core/Pagination/Pagination";
import CourseIntro from "./Components/CourseIntro.vue";
import CourseVideo from "./Components/CourseVideo.vue";
import CourseInfo from "./Components/CourseInfo";
import { mapActions, mapState } from "vuex";

export default {
  name: "OnlineCourse",

  components: {
    CourseHeader,
    Pagination,
    CourseVideo,
    CourseInfo,
    CourseIntro
  },

  data() {
    return {
      lectureId: 1
    };
  },

  created() {
    this.getOnlineCourse(this.$route.params.id).then(() => {
      this.getLecture(this.getLecturesId[0]);
    });
    this.getAttendenceRate(this.$route.params.id);
  },

  methods: {
    // value of pagination === id of lecture
    getLectureMethod(lectureId) {
      this.lectureId = lectureId;
      this.getLecture(lectureId);
    },
    ...mapActions("CourseArea", [
      "getOnlineCourse",
      "getLecture",
      "getAttendenceRate"
    ]),
    setLecture(lectureId) {
      this.lectureId = lectureId;
    }
  },

  computed: {
    ...mapState("CourseArea", ["OnlineCourse"]),
    getLeacturesCount() {
      return this.OnlineCourse.courseIndex
        .map((e) => e.lectures.map((e) => e.id))
        .flat(Infinity).length;
    },
    getLecturesId() {
      return this.OnlineCourse.courseIndex
        .map((e) => e.lectures.map((e) => e.id))
        .flat(Infinity);
    },
    id: {
      set() {
        this.OnlineCourse.lecture.id;
      },
      get() {
        return this.OnlineCourse.lecture.id;
      }
    }
  }
};
</script>

<style>
.en-style {
  direction: ltr;
}
</style>
