<template>
  <div class="text-center" id="pagination">
    <v-pagination
      v-model="findLecture"
      :value="findLecture"
      :length="length"
      :lecturesId="lecturesId"
      :total-visible="totalVisible"
      @input="handleChange"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Pagination",

  props: {
    value: Number,
    index: Number,
    length: Number,
    lecturesId: Array,
    totalVisible: {
      type: Number,
      default: 7
    },
    changeCallback: Function
  },

  data() {
    return {
      page: this.index,
      x: this.id || 1
    };
  },

  methods: {
    handleChange(lectureId) {
      this.changeCallback(this.lecturesId[lectureId - 1]);
    }
  },

  computed: {
    ...mapState("CourseArea", ["OnlineCourse"]),
    findLecture: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.lecturesId.findIndex((e) => e == this.value) + 1;
      }
    }
  }
};
</script>

<style scoped>
/deep/ .v-pagination__item,
/deep/ .v-pagination__navigation {
  background-color: var(--gray-color) !important;
  border-radius: 10px !important;
  font-weight: 900 !important;
  color: var(--primary-color) !important;
  font-size: 18px !important;
  box-shadow: none !important;
}
/deep/ .v-icon {
  color: var(--primary-color) !important;
}
/deep/ .v-pagination__item--active {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
/deep/ .v-pagination__item:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}
</style>
