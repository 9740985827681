<template>
  <div class="moreInformation-section" id="courseInfo">
    <div class="uk-container">
      <div uk-grid>
        <!-- description -->
        <div class="uk-width-2-3@m">
          <div class="courseL">
            <!-- part 1 -->
            <div>
              <h4>{{ $t("golasOfCourse") }}</h4>
              <p>{{ info.course_targets }}</p>
            </div>
            <!-- end part 1 -->
            <!-- part 2 -->
            <div>
              <h4>{{ $t("requirementsToPassTheCourse") }}</h4>
              <p>{{ info.course_requiremnts }}</p>
            </div>
            <!-- end part 2 -->
          </div>
        </div>
        <!-- index -->
        <div class="uk-width-1-3@m">
          <div class="courseS">
            <div class="uk-overflow">
              <h4>{{ $t("courseIndex") }}</h4>
              <v-treeview :items="info" :itemChildren="'lectures'"
                ><template v-slot:prepend="{ item }">
                  <div
                    style="cursor: pointer"
                    :class="item.id && item.id == id ? 'treeview-index' : ''"
                  >
                    <p @click="getLectureMethod(item.id)">
                      {{ item.title }}
                    </p>
                  </div>
                </template></v-treeview
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CourseInfo",

  props: {
    info: {
      type: Array
    },
    id: {
      type: Number
    }
  },

  data() {
    return {};
  },

  methods: {
    ...mapActions("CourseArea", ["getLecture"]),
    getLectureMethod(lectureId) {
      if (typeof lectureId === "number") {
        this.getLecture(lectureId);
        this.$emit("click", lectureId);
      }
    }
  }
};
</script>

<style lang="scss">
.v-treeview-node__prepend {
  min-width: 100%;
}
.treeview-index {
  background-color: #dedaff;
  p {
    padding: 10px;
  }
}
</style>
